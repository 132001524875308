import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import "./Styles.css";
import Sticky from "@ui/sticky";

const VoiceArtist = ({ data }) => {
    const content = normalizedData(data?.homePage?.content || []);


    return (

        <Layout pageTitle="" className="white-version">
            <Header
                data={{
                    ...data.header,
                    ...data.navigation,
                    socials: data.site.siteMetadata.socials,
                }}
            />

        <div>
            <div>
                <main className="main-page-wrapper">
                    <div className="rn-slider-area">
                        <div className="container">
                            <div className="row row--30 pt--100 pt_sm--50">
                                <div className="col-lg-6">
                                    <div className="d-flex flex-wrap align-content-start h-100">
                                        <Sticky
                                            className="sticky-top-slider"
                                            top="200px"
                                        >
                                            <div className="banner-details-wrapper-sticky slide">
                                                <div className="thumbnail">
                                                    <img
                                                        src={
                                                            "../static/anuj-p-1.png"
                                                        }
                                                    />
                                                </div>
                                                <div className="banner-title-area pt--30">
                                                    <h1
                                                        className="title"
                                                        style={{
                                                            color: "#000",
                                                        }}
                                                    >
                                                        Hi, I’m{" "}
                                                        <span>Anuj</span>
                                                        <br />
                                                        {/*<span className="span" style={{ color: '#000' }}> Software Engineer.</span>*/}
                                                    </h1>
                                                    <p className="disc">
                                                        As a professional
                                                        voice-over artist, I’ve
                                                        voiced a plethora of
                                                        commercials, jingles,
                                                        corporate films, TVCs,
                                                        audiobooks,
                                                        documentaries and
                                                        e-learning projects. I’m
                                                        the official Hindi voice
                                                        of The Falcon (Avengers
                                                        superhero) and
                                                        Mahershala Ali (Remy
                                                        Danton; House of Cards).
                                                    </p>
                                                </div>
                                            </div>
                                        </Sticky>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="sticky-home-wrapper">
                                        <div className="rn-about-area section-height">
                                            <div className="inner">
                                                <h5 className="title">
                                                    Voice Artist | Podcaster
                                                </h5>
                                                <p className="about-disc">
                                                    For over 15+ years, I’ve
                                                    been using my voice to
                                                    entertain, narrate, and
                                                    market brands in the most
                                                    convincing, compelling,
                                                    conversational, and
                                                    professional manner. As a
                                                    former radio show host, I’m
                                                    known for my authoritative,
                                                    warm, rich, deep, and smooth
                                                    vocal styles. 
                                                </p>

                                                <p className="about-disc">
                                                    Renowned for dubbing for
                                                    Anthony Mackie as Sam
                                                    Wilson/Falcon in Marvel
                                                    Cinematic Universe films in
                                                    Hindi, I dub television and
                                                    film characters
                                                    professionally. I have
                                                    hosted several radio shows
                                                    in English, Hindi, and
                                                    Regional languages on
                                                    popular stations such as All
                                                    India Radio, Radio Biryani,
                                                    Radio City 91.1FM, and many
                                                    more.
                                                </p>
                                                <p className="about-disc">
                                                    I host my education-based
                                                    podcast - EdTalk with Anuj,
                                                    a talk-show series focused
                                                    on conversations around the
                                                    education system in India.
                                                    Also, I have scripted
                                                    advertisements, films, live
                                                    events, and promotional
                                                    materials. One of my major
                                                    projects is the entire
                                                    language conversion of the
                                                    Telegu film Eega into its
                                                    Hindi Version. I was
                                                    responsible for writing the
                                                    Hindi version of the film.
                                                </p>
                                                <p className="about-disc">
                                                    I can help put your brand
                                                    message across to your
                                                    audience or dub for a
                                                    character in your film.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sticky-home-wrapper">
                                        <div className="rn-about-area section-height">
                                            <div className="inner">
                                                <h5 className="title">
                                                    Want to hear me dub for a
                                                    film character? Check the
                                                    videos below.  
                                                </h5>

                                                <div className="flex-50-m">
                                                    <div className="flex-50-i">
                                                        <iframe
                                                            width="100%"
                                                            height="215"
                                                            src="https://www.youtube.com/embed/oJrzTCU1cO8"
                                                            title="YouTube video player"
                                                            frameborder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowfullscreen
                                                        ></iframe>
                                                    </div>

                                                    <div className="flex-50-i">
                                                        <iframe
                                                            width="100%"
                                                            height="215"
                                                            src="https://www.youtube.com/embed/jTT3RFMCWV4"
                                                            title="YouTube video player"
                                                            frameborder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowfullscreen
                                                        ></iframe>
                                                    </div>
                                                    <div className="flex-50-i">
                                                        <iframe
                                                            width="100%"
                                                            height="215"
                                                            src="https://www.youtube.com/embed/qTE453yc1rc"
                                                            title="YouTube video player"
                                                            frameborder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowfullscreen
                                                        ></iframe>
                                                    </div>
                                                    <div className="flex-50-i">
                                                        <iframe
                                                            width="100%"
                                                            height="215"
                                                            src="https://www.youtube.com/embed/BjHcqVJETdk"
                                                            title="YouTube video player"
                                                            frameborder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowfullscreen
                                                        ></iframe>
                                                    </div>
                                                    <div className="flex-50-i">
                                                        <iframe
                                                            width="100%"
                                                            height="215"
                                                            src="https://www.youtube.com/embed/NLRnshDleSc"
                                                            title="YouTube video player"
                                                            frameborder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowfullscreen
                                                        ></iframe>
                                                    </div>
                                                    <div className="flex-50-i">
                                                        <iframe
                                                            width="100%"
                                                            height="215"
                                                            src="https://www.youtube.com/embed/LLyT5-SXvtE"
                                                            title="YouTube video player"
                                                            frameborder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowfullscreen
                                                        ></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sticky-home-wrapper">
                                        <div className="rn-about-area section-height">
                                            <div className="inner">
                                                <h5 className="title">
                                                    Contact Me
                                                </h5>

                                                <p className="about-disc">
                                                    Let’s connect on your event,
                                                    either social or corporate.
                                                    I’m available for freelance
                                                    work as a master of
                                                    ceremony, singer, actor,
                                                    voice artist or educator. 
                                                </p>
                                                <p>
                                                    Email: anujgurwara@gmail.com 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
        <Footer data={{ ...data.footer }} className="section-separator" />
        </Layout>
    );
};




export const query = graphql`
    query VoiceArtist {
        site {
            ...Site
        }
        header: general(section: { eq: "header-1-white" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu-1" }) {
            menu {
                ...Menu01
            }
        }
        footer: general(section: { eq: "footer-1-white" }) {
            ...Footer01
        }
        homePage(title: { eq: "politician-home-white" }) {
            content {
                ...Content01
            }
        }
        allArticle(limit: 3) {
            nodes {
                ...Article
            }
        }
    }
`;

VoiceArtist.propTypes = {
    data: PropTypes.shape({
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                socials: PropTypes.arrayOf(PropTypes.shape({})),
                contact: PropTypes.shape({
                    phone: PropTypes.string,
                    email: PropTypes.string,
                }),
                getform_url: PropTypes.string,
            }),
        }),
     
        
        navigation: PropTypes.shape({}),
        header: PropTypes.shape({}),
        footer: PropTypes.shape({}),
    }),
};

export default VoiceArtist;
